.navLink {
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-decoration: none;
  transition: color var(--hover-anim-dur);
  color: var(--white-color);

  &:hover, &:focus {
    outline: none;
    color: var(--green-color);
  }

  .icon {
    margin-right: 10px;
  }
}
