.badge {
  display: inline-block;
  position: relative;

  .badgeLabel {
    $size: 25px;

    background: var(--green-color);
    pointer-events: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $size;
    height: $size;
    border-radius: var(--border-radius);
    color: var(--white-color);
    position: absolute;
    z-index: 10;
    bottom: -$size / 8; left: -$size / 8;
  }
}
