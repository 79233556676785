.header {
  .navLink {
    text-transform: uppercase;
    font-weight: 700;

    &:hover, &:focus {
      color: var(--green-color);
    }
  }

  &[data-dark='true'] {
    svg {
      fill: var(--black-color);

      path[stroke] {
        stroke: var(--black-color);
      }
    }

    .navLink {
      color: var(--black-color);

      &:hover, &:focus {
        color: var(--green-color);
      }
    }
  }
}

.headerFixed {
  position: fixed !important;
  top: 0 !important;
  background: var(--white-color);
}

.headerIcon {
  height: 20px;
  margin: 0 15px;

  .icon {
    height: inherit;
  }

  svg {
    transition: var(--hover-anim-dur);
  }

  &:hover, &:focus {
    svg {
      fill: var(--green-color);
    }
  }
}
