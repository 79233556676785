.radioButton {
  $size: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: $size;
  height: $size;
  border: 2px solid var(--green-color);
  border-radius: var(--sm-border-radius);
  cursor: pointer;
  transition: var(--hover-anim-dur);

  &:hover, &:focus {
    outline: none;
    border-width: 3px;
  }

  &[data-checked='true'] {
    background: var(--green-color);
  }

  .mark {
    width: $size * 0.5;
  }
}
