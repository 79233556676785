@import './src/styles/breakpoints';
@import './src/styles/mixins';

.main {
  flex: 1 0 auto;
}

.slider {
  width: 100%;
  height: 100vh;

  .slide {
    height: 100vh;
  }
}

.slideContent {
  padding: var(--page-med-gap);
  color: var(--white-color);

  .title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .subtitle {
    font-weight: 400;
  }

  .mainText {
    font-weight: 800;
    margin: var(--gap-small) 0;
    word-break: break-all;
  }
}

.section {
  margin: var(--page-gap) 0;
  padding: 0 var(--page-gap);

  .itemsGroup {
    @include cardGroup(auto-fit);
    width: 100%;
    position: relative;
  }
}

.categoryGroup {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin: var(--gap-small) 0;

  .category {
    margin: var(--gap-slt);
  }
}

.brandsGroup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
  margin: var(--default-gap) 0;

  .brand {
    &:hover, &:focus {
      svg {
        fill: var(--green-color);
      }
    }

    svg {
      transition: var(--hover-anim-dur);
      fill: var(--black-color);
      height: 50px;
      max-width: 75px;
    }
  }
}

.point {
  width: 100%;
  margin: var(--default-gap) 0;
}

.sectionButton {
  margin: var(--gap-mdp) 0;
}

.geoPicker {
  width: 100%;
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .categoryGroup {
    .category {
      margin: calc(var(--default-gap) * 2) 0;
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'sm')) {
  .section {
    padding: 0 calc(var(--page-gap) * var(--gap-scale));
  }
}
