@import './src/styles/breakpoints';

.container {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fit, minmax(150px, 450px));
  width: 100%;
  margin-top: var(--gap-mdp);
  position: relative;
}

.sectionTitle {
  margin-bottom: var(--gap-mdp);
}

.item {
  &:not(:first-child) {
    margin-top: var(--default-gap);
  }
}

.orderInfo {
  list-style-type: none;

  .orderInfoField {
    display: flex;
    align-items: center;
    margin: var(--gap-small) 0;
  }

  .icon {
    fill: var(--green-color);
    margin-right: var(--gap-slt);
  }
}

@media screen and (max-width: map-get($breakpoint, 'lg')) {
  .section {
    margin: var(--default-gap) 0;
  }

  .order {
    .orderInfo {
      .orderInfoField {
        margin: var(--default-gap) 0;
      }
    }
  }
}
