.menu {
  margin: var(--gap-small) 0;

  .menuText {
    font-size: var(--text-md);
    font-weight: 700;
    margin: var(--gap-slt) 0;
    color: var(--black-color);

    &:hover, &:focus {
      color: var(--green-color);
    }
  }
}

.inputBox {
  width: 100%;
  background: var(--white-color);
  position: sticky;
  top: 0;
  z-index: 20;

  .search {
    width: 100%;
    margin-top: var(--gap-mdp);
    margin-bottom: var(--gap-slt);
  }
}

.subcategoryLink {
  transition: var(--hover-anim-dur);

  &:not(:last-child) {
    margin-bottom: var(--gap-mdp);
  }

  &:hover, &:focus {
    color: var(--green-color);
    transform: translateX(10px);
  }
}