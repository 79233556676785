%section {
  display: flex;
  flex-direction: column;
}

.footer {
  @extend %section;
  background: var(--black-color);
  padding: var(--page-med-gap) var(--page-med-gap) var(--gap-mdp);
  border-radius: var(--lg-border-radius) var(--lg-border-radius) 0 0;
  flex: 0 0 auto;

  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .copyright {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--white-color);
    text-align: center;
    margin-top: var(--default-gap);
  }


  .about {
    @extend %section;
    max-width: 350px;

    .logo {
      align-self: flex-start;
      margin-bottom: var(--gap-small);
    }

    .socialBlock {
      display: inline-block;

      &:before {
        content: '';
        display: inline-block;
        height: 2px;
        border-radius: var(--border-radius);
        width: 150%;
        background: var(--green-color);
        margin: var(--gap-small) 0;
      }

      .socialLink {
        path {
          transition: var(--hover-anim-dur);
        }

        &:not(:first-child) {
          margin-left: var(--gap-small);
        }

        &:hover, &:focus {
          path {
            fill: var(--green-color);
          }
        }
      }
    }
  }

  .footerSectionGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .footerSection {
      margin: 0 var(--default-gap);
      max-width: 250px;

      .title {
        color: var(--white-color);
        margin-bottom: var(--gap-small);
      }
    }
  }
}
