.logoLink {
  display: flex;
  align-items: center;
  height: 100%;

  .logo {
    height: 100%;
    max-height: 40px;
    transition: var(--hover-anim-dur);

    &:hover, &:focus {
      stroke: var(--green-color);
    }
  }
}
