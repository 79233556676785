@import './src/styles/breakpoints';

.shopInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    $size: 45px;
    width: $size;
    height: $size;
    fill: var(--green-color);
  }

  .infoGroup {
    display: flex;
    flex-direction: column;
    margin-left: var(--default-gap);

    .infoBlock {
      margin: var(--gap-slt) 0;
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .shopInfo {
    flex-direction: column;

    .icon {
      $size: 25px;
      width: $size;
      height: $size;
      margin-left: 0;
    }

    .infoGroup {
      margin: var(--gap-mdp) 0;
      text-align: center;
    }
  }
}
