.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  cursor: pointer;
  transition: var(--hover-anim-dur);
  user-select: none;

  &:hover, &:focus {
    color: var(--green-color);
    outline: none;
  }
}
