@import '../../styles/mixins';

.orderCard {
  @include card;

  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    color: var(--green-color);
  }

  .subtitle {
    margin-top: var(--gap-slt);
    margin-bottom: var(--gap-small);
  }
}