.searchMenu {
  max-width: 250px;
  background: var(--white-color);
  box-shadow: 10px 13px 38px -10px #00000040;
  padding: var(--gap-small);
  min-width: 250px;
  border-radius: var(--border-radius);
  transition-property: background-color, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  &.dragging {
    background-color: #f0f0f0; // меняем цвет фона при перетаскивании
    transform: scale(1.1);
  }

  .results {
    @import './src/styles/scrollbar';
    display: flex;
    flex-direction: column;
    padding: var(--gap-slt);
    margin-top: var(--gap-slt);
    max-height: 250px;
    overflow-y: auto;

    .resultLink {
      &:not(:first-child) {
        margin-top: var(--gap-small);
      }
    }

    .showMore {
      font-weight: 600;
      text-align: center;
    }
  }

  .searchFieldContainer {
    position: relative;

    .searchInput {
      padding-right: 40px;
    }

    .searchIcon {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .loaderBlock {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #f0f0f0ed;
    transition-property: display;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  .loaderBlock .loaderSpiner {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid;
    border-color: #92fe9d #92fe9d transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

  .loaderBlock.isSearching {
    display: block;
  }

}