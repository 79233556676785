@import './src/styles/breakpoints';

.textBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white-color);

  .text {
    color: var(--white-color);
    margin: var(--gap-slt) 0 var(--gap-slt) var(--gap-small);
  }

  a {
    &.text {
      transition: var(--hover-anim-dur);

      &:hover {
        color: var(--green-color);
      }
    }
  }
}
