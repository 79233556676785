.selectList {
  @import './src/styles/scrollbar';

  width: 100%;
  padding: var(--gap-small);
  background: var(--grey-color);
  border: var(--sm-border-radius);
  border-radius: var(--sm-border-radius);
  box-shadow: 0 4px 35px -5px rgba(0, 0, 0, 0.25);
  top: calc(var(--gap-small) + 40px);
  position: absolute;
  z-index: 20;
  max-height: 250px;
  overflow-y: scroll;

  .option {
    margin: var(--gap-slt) 0;

    &.active {
      color: var(--green-color);
    }
  }
}
