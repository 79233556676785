.sliderContainer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .arrow {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: var(--black-color);
    transition: var(--hover-anim-dur);
    position: absolute;

    &:hover, &:focus {
      fill: var(--green-color);
    }
  }

  .leftArrow {
    left: -8%;
  }

  .rightArrow {
    right: -8%;
  }
}

.slider {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
