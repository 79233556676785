.inputRadio {
  $size: 20px;

  border-radius: 25px;
  border: 2px solid var(--green-color);
  cursor: pointer;
  width: $size;
  height: $size;
  transition: var(--hover-anim-dur);

  &.active, &:hover, &:focus {
    background: var(--green-color);
    outline: none;
  }
}
