.footerMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--black-color);
  padding: var(--page-gap);
  border-radius: var(--lg-border-radius) var(--lg-border-radius) 0 0;
  flex: 0 0 auto;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .logo {
      align-self: center;
      margin: var(--gap-slt) 0;
    }

    .textBlock {
      display: flex;
      align-items: center;
      color: var(--white-color);
      margin: var(--gap-small) 0;

      .text {
        margin-left: var(--gap-small);
      }
    }

    .sectionGroup {
      .section {
        margin: var(--default-gap) 0;

        &:last-child:after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: var(--green-color);
          margin: var(--default-gap) 0;
          border-radius: var(--border-radius);
        }

        .title {
          color: var(--white-color);
          margin: var(--gap-mdp) 0;
        }
      }
    }

    .socialBlock {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .socialLink {
        svg {
          transition: var(--hover-anim-dur);
        }

        &:hover, &:focus {
          svg {
            fill: var(--green-color);
          }
        }
      }
    }
  }

  .copyright {
    color: var(--white-color);
    text-align: center;
    margin-top: var(--default-gap);
  }
}
