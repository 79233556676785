@import './src/styles/breakpoints';

.overlay {
  position: fixed;
  z-index: 800;
  width: 100%;
  height: 100%;
}

.modal {
  background: var(--white-color);
  border-radius: var(--border-radius);
  padding: var(--gap-mdp);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  overflow: auto;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--gap-mdp);

    .principalText {
      display: flex;
      flex-direction: column;
      margin-right: var(--gap-mdp);

      .title {
        margin-bottom: var(--gap-slt);
        max-width: 450px;
      }
    }
  }

  .closeButton {
    margin-bottom: auto;
  }

  .closeIcon {
    fill: var(--black-color);
    transition: var(--hover-anim-dur);

    &:hover {
      fill: var(--green-color);
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: var(--page-gap);
  }
}
