@import './src/styles/breakpoints';

.geoPicker {
  .description {
    line-height: 2rem;
    text-align: center;
    margin: var(--gap-mdp);
  }

  .optionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .optionGroup {
    width: 100%;

    .option {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: var(--gap-mdp) 0;
    }
  }
}

@media screen and (max-width: map-get($breakpoint, 'md')) {
  .geoPicker {
    .optionGroup {
      .option {
        flex-direction: column;
      }
    }
  }
}
